/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '@angular/material/theming';

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #f4f4f4;
}

.center {
  display: flex;
  justify-content: center;
}

.right {
  display: flex;
  justify-content: flex-end;
}

.left {
  margin-top: 25px;
}

#router-outlet {
  height: 100vh;
  display: flex;
  justify-content: center;
}

#page-container {
  justify-content: center;
  padding-top: 6vh
}
@media  screen and (max-height: 1080px) {
  #page-container {
    padding-top: 8vh;
  }
}

%page-tile {
  border: 1px #0a756c;
  margin: auto;
  padding-top: 1vh;
}

.page-tile {
  @extend %page-tile;
  max-width: 70vw;
}

.page-tile-wide {
  @extend %page-tile;
  max-width: 95vw;
}

.page-tile-narrow {
  @extend %page-tile;
  max-width: 50vw;
}

@media ($mat-small) {
  .page-tile, .page-tile-wide {
    max-width: 95vw;
  }

  .page-tile-narrow {
    max-width: 70vw;
  }
}

@media ($mat-xsmall) {
  .page-tile, .page-tile-wide, .page-tile-narrow {
    max-width: 95vw;
  }
}
