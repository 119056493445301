@import '@angular/material/theming';
@include mat-core();

/**
Palettes generates from Proactive's colors
 */
$md-proactive-accent: (
  50 : #f2f8e4,
  100 : #e0edbd,
  200 : #cbe191,
  300 : #b6d464,
  400 : hsl(76, 57%, 53%),
  500 : #000E4C,
  600 : #8ebc1e,
  700 : #83b419,
  800 : #79ac14,
  900 : #689f0c,
  A100 : #eaffcd,
  A200 : #d5ff9a,
  A400 : #c0ff67,
  A700 : #b6ff4d,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$md-proactive-primary: (
  50 : #e0f4f3,
  100 : #b3e3e1,
  200 : #80d0cd,
  300 : #4dbdb8,
  400 : #26afa9,
  500 : #000E4C,
  600 : #009992,
  700 : #008f88,
  800 : #00857e,
  900 : #00746c,
  A100 : #a2fff8,
  A200 : #6ffff4,
  A400 : #3cfff0,
  A700 : #23ffee,
  contrast: (
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$md-proactive-warn: (
  50 : #e0f4f3,
  100 : #971107,
  500 : #971107,
  700 : #971107,
  contrast: (
    50 : #000000,
    100 : #000000,
    500 : #ffffff,
    700 : #ffffff,
  )
);

// Generate MaterialDesign palettes & theme.
$proactive-primary: mat-palette($md-proactive-primary);
$proactive-accent:  mat-palette($md-proactive-accent);
$proactive-warn:    mat-palette($md-proactive-warn);
$proactive-theme: mat-light-theme($proactive-primary, $proactive-accent, $proactive-warn);
@include angular-material-theme($proactive-theme);

.mat-header-cell {
  // Accessibility: Improves contrast to AAA
  color: rgba(0, 0, 0, 0.87);
  // Accessibility: Bold headers (GDS Recommendation)
  font-weight: 700;
}

mat-row:focus-within, mat-row.selected {
  margin-top: -5px;
}

mat-row.selected {
  background-color: gold;
  border-bottom-color: rgba(0, 0, 0, 0.57);
  border-bottom-width: 3px;
  border-top-color: rgba(0, 0, 0, 0.57);
  border-top-width: 3px;
}

.mat-focus-indicator:focus, .mat-focus-indicator.mat-active, a:focus, mat-table:not(.individual-focus) mat-row:focus-within,
.map-container:focus-within, input[type=file]:focus, mat-checkbox:focus-within {
  border: solid 3px black;
  outline: none;
}

.mat-form-field-appearance-fill {
  & .mat-form-field-flex {
    border: 1px solid #8FA3FF;
    border-radius: 4px !important;
    background-color: #ffff !important;
  }

  & .mat-form-field-flex:focus-within {
    border: solid 1px black;
  outline: none;
  }
}

mat-spinner.centered {
  margin: 0 auto;
}

.gm-style button.gm-control-active:focus{
  border: solid 3px black !important;
  outline: none;
}

.mat-error{
  background: #FFFFFF;
  font-size: larger;
  font-weight: bold;
  color: #971107;
}


.mat-card {
  & a {
    color: black;
  } 
}

.mat-form-field-underline {
  display: none;
}

.mat-focus-indicator:focus {
  border: none ;
}
